<template>
  <div id='about'>
    <PageTitle text='JPFFとは' />
    <SectionTitle icon='mdi-book-open-variant' text='沿革' />
    <p>
      1978年、東西9団体によって設立。
    </p>
    <p>
      関西地区、学生団体が地域的な問題等により脱退。
      <br />
      関東地区の社会人6団体により活動を継続。
    </p>
    <p>
      1988年、協会創立10周年記念大会として、関西地区のプライベート団体関西プライベートフットボール連盟選抜をご招待して記念交流戦を実施。
      <br />
      東西役員により将来のビジョンが初めて話し合われ、統一に向けての交流が頻繁に行われるようになる。
    </p>
    <p>
      1993年の協会創立15周年記念大会において、統一の意思確認が東西において行われ、一年後の全国組織発足に向け東西にて準備委員会が設置される。
    </p>
    <p>
      1994年4月11日東西56チーム、2000名の会員を有する日本最大のプライベート全国組織として新たにスタートする。
    </p>
    <p>
      1995年、全国組織発足の目的のひとつであった全日本選手権大会を横浜スタジアムにおいて開催。
      <br />
      以後継続して開催されている。
    </p>
    <SectionTitle icon='mdi-book-open-variant' text='目的' />
    <p>
      日本におけるアメリカンフットボールの社会教育としての普及振興及び、青少年の育成をその目的とする。
    </p>
    <SectionTitle icon='mdi-book-open-variant' text='設立趣旨' />
    <p>
      日本におけるアメリカンフットボールの底辺拡大のためには、全国で活躍しているプライベート、クラブチームがお互いに手を取り合い組織として活動できる団体を作ることが必要不可欠と考え、全国の仲間に呼びかけご賛同いただいた５６チームにてスタート。
    </p>
    <SectionTitle icon='mdi-book-open-variant' text='組織' />
    <p>
      全国を９地区に分割、東西支部に分けている。
    </p>
    <p>
      東西それぞれに支部事務局を持ち、その上部に全国を統括する本部事務局を設置。
      <br />
      東西より選出された専務理事を加えた本部役員にて組織を運営。
    </p>
    <SectionTitle icon='mdi-book-open-variant' text='活動' />
    <SectionSubTitle text='競技活動' />
    <p>
      所属団体のリーグ戦活動の補助。
      <br />
      所属チーム間の交流戦の企画、実施。
      <br />
      全国の他団体との交流戦の企画、実施。
      <br />
      プレイオフ、全日本選手権大会の企画、実施。
    </p>
    <SectionSubTitle text='啓蒙活動' />
    <p>
      安全面充実のためのメディカルクリニックの企画、実施。
      <br />
      テクニカルクリニックの企画、実施。
    </p>
    <SectionSubTitle text='広報活動' />
    <p>
      所属団体の活動状況を随時専門誌に掲載。
      <br />
      特別記念試合、プレイオフ、全日本選手権大会等の特集記事の掲載。
      <br />
      TV、スポーツ紙、一般紙に対する定期的な情報の提供。
      <br />
      全国フットボールプロショップにおける協会活動報告の掲示。
      <br />
      専用掲示板の確保。
      <br />
      協会広報誌の定期的な発行、関係団体への配布。
    </p>
    <SectionSubTitle text='普及活動' />
    <p>
      全国クラブチーム名簿を作成、競技人口の増加につとめる。
      <br />
      新たに作られたフットボールチームの運営を全面的にバックアップする。
      <br />
      全国の他団体との交流に積極的に取り組み、ネットワークづくりを行う。
    </p>
    <SectionTitle icon='mdi-book-open-variant' text='加盟' />
    <p>
      日本協会未登録のチームであれば、そのチーム形態を問題としない。
    </p>
    <p>
      活動地域により東西支部どちらかに所属し活動することになる。
    </p>
    <p>
      加盟の形態は、正式加盟、協力団体など様々なものがあり自己のチームの状況により選択することができる。
    </p>
    <p>
      加盟チーム、会員には様々な特典があり充実したチーム活動が可能となる。
    </p>
    <SectionTitle icon='mdi-book-open-variant' text='事務局' />
    <p>
      協会活動に対するお問い合わせにつきましては、すべて協会事務局へお願いいたします。
      <br />
      加盟に関する資料の請求なども事務局へお願いいたします。
    </p>
    <p>
      〒253-0054
      <br />
      神奈川県茅ヶ崎市東海岸南6-3-22
      <br />
      茅ヶ崎東海岸ハイツ211
      <br />
      日本プライベートフットボール協会事務局
      <br />
      TEL/FAX：0467-82-1397
    </p>
  </div>
</template>

<script>
import PageTitle from '@/components/PageTitle'
import SectionTitle from '@/components/SectionTitle'
import SectionSubTitle from '@/components/SectionSubTitle'

export default {
  components: { PageTitle, SectionTitle, SectionSubTitle },
}
</script>